import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'tpm_budget_year_form_jiulei',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'month') {
        v.refresh = true;
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const quater = this.getRule('quater');
      const month = this.getRule('month');

      quater.on.change = (e) => {
        this.setValue({
          halfYear: Number(e) < 3 ? 'up_year' : 'down_year',
          month: null,
        });
        this.disabled(!e, 'month');
      };

      month.on.optionCallback = (options, value) => options.map((subItem) => {
        const v = subItem;
        if (quater.value === '1' && Number(v.dictCode) < 4) {
          v.disabled = false;
        } else if (quater.value === '2' && Number(v.dictCode) > 3 && Number(v.dictCode) < 7) {
          v.disabled = false;
        } else if (quater.value === '3' && Number(v.dictCode) > 6 && Number(v.dictCode) < 10) {
          v.disabled = false;
        } else if (quater.value === '4' && Number(v.dictCode) > 9) {
          v.disabled = false;
        } else {
          v.disabled = true;
        }

        return v;
      });

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmFiscalYearSettingController/query', {
            id: this.formConfig.row.id,
            directSystemId: this.formConfig.row.directSystemId,
            directSystemName: this.formConfig.row.directSystemName,
          })
          .then((res) => {
            if (res.success) {
              this.setValue(res.result);
              this.disabled(false, 'month');
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmFiscalYearSettingController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmFiscalYearSettingController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
